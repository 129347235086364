import React, { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import css from "./style.module.scss";
import { Avatar, Logo, Container, QuotesForm, FileUnknownOutlined } from "../../components";

import AboutPopup from "../../components/quotes-panel/components/profile/decision-support/popups/about-popup";

import _ from "lodash";

const IndexPage = (props) => {
  const data = useStaticQuery(graphql`
    query assets2 {
      site {
        siteMetadata {
          baseUrl
          agent {
            id
            assets {
              image
            }
            baseUrl
            imageSrc
            name
            phone
            whatsapp
            email
            website
            title
            calendly
            bio
          }
        }
      }
    }
  `);
  const [aboutPopupVisible, setAboutPopupVisible] = useState(false);

  const openAboutPopup = () => setAboutPopupVisible(true);
  const closeAboutPopup = () => setAboutPopupVisible(false);

  return (
    <Container className={css.homeContainer}>
      <AboutPopup
        data={data}
        visible={aboutPopupVisible}
        onClose={closeAboutPopup}
      />
      {/* <Helmet>
          <meta viewport="width=device-width, initial-scale=1, minimum-scale=1,maximum-scale=1, shrink-to-fit=no"/>
        </Helmet> */}
      {/* //<SEO title="Home" /> */}
      <Container className={css.agentDataContainer}>
        <Container className={css.agentDataContainerContent}>
          <Container className={css.logoContainer}>
            <Container className={css.logo}>
              <svg>
                <use xlinkHref="./sprite.svg#apollo-icon" />
              </svg>
            </Container>
            <Container className={css.logoText}>
              <span>Apollo </span> Quotes
            </Container>
          </Container>
          <Container className={css.agentData}>
            <Container className={css.avatarContainer}>
              <Avatar
                className={css.avatar}
                link=""
                round="true"
                src={data?.site?.siteMetadata?.agent?.imageSrc}
              />
            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container className={css.agentName}>
                {data.site.siteMetadata.agent.name}
              </Container>
              <Container className={css.agentTitle}>
                {data.site.siteMetadata.agent.title}
              </Container>
              <Container
                onPress={() => {
                  openAboutPopup();
                }}
                className={css.bioButton}
              >
                {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio
              </Container>
              <Container>
                <Container className={css.agentInfo}>
                  <svg className={css.phone}>
                    <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                  </svg>

                  <span>{data.site.siteMetadata.agent.phone}</span>
                </Container>
                {data?.site?.siteMetadata?.agent?.calendly && (
                  <a
                    target="_blank"
                    href={data?.site?.siteMetadata?.agent?.calendly}
                  >
                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#schedule-call-icon" />
                      </svg>
                      <span>Schedule a Call</span>
                    </Container>
                  </a>
                )}
                {false && (
                  <a
                    target="_blank"
                    href={"https://m.me/ApolloInsuranceAdvisors"}
                  >
                    <Container className={css.agentInfo}>
                      <svg>
                        <use xlinkHref="/sprite.svg#facebook-icon" />
                      </svg>
                      <span>Send Message</span>
                    </Container>
                  </a>
                )}
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={css.agentDataContainerMobile}>
        <Container className={css.mobileBg}>
          <Container className={css.mobileAgentData}>
            <Container className={css.mobileLogoContainer}>
              <Logo />
            </Container>

            <Container className={css.avatarContainer}>
              <Avatar
                className={css.avatar}
                link=""
                round="true"
                src={data?.site?.siteMetadata?.agent?.imageSrc}
              />
            </Container>

            <Container className={css.buttonsAndTexts}>
              <Container>
                <Container className={css.agentName}>
                  {data.site.siteMetadata.agent.name}
                </Container>
                <Container className={css.agentTitle}>
                  {data.site.siteMetadata.agent.title}
                </Container>

                <Container className={css.agentInfoContainer}>
                  <Container className={css.agentInfo}>
                    <svg className={css.phone}>
                      <use xlinkHref="/sprite.svg#phone-outlined-icon" />
                    </svg>

                    <span>{data.site.siteMetadata.agent.phone}</span>
                  </Container>
                  {data?.site?.siteMetadata?.agent?.calendly && (
                    <a
                      target="_blank"
                      href={data?.site?.siteMetadata?.agent?.calendly}
                    >
                      <Container className={css.agentInfo}>
                        <svg>
                          <use xlinkHref="/sprite.svg#schedule-call-icon" />
                        </svg>
                        <span>Schedule a Call</span>
                      </Container>
                    </a>
                  )}
                  {false && (
                    <a
                      target="_blank"
                      href={"https://m.me/ApolloInsuranceAdvisors"}
                    >
                      <Container className={css.agentInfo}>
                        <svg>
                          <use xlinkHref="/sprite.svg#facebook-icon" />
                        </svg>
                        <span>Send Message</span>
                      </Container>
                    </a>
                  )}
                </Container>
              </Container>
              <Container
                onPress={() => {
                  openAboutPopup();
                }}
                className={css.bioButton}
              >
                {/* {data.site.siteMetadata.agent.name.split(" ")[0]}'s Bio */}
                View Bio
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
      <Container className={css.mainContainer}>
        <Container className={css.mainContainerInner}>
          <Container className={css.headingsContainer}>
            <h1 className={`h1 ${css.healthInsurance}`}> Health insurance</h1>
            <h1 className={`h1 ${css.quotes}`}> Quotes and</h1>
            <h1 className={`h1 ${css.plans}`}> Plans</h1>
          </Container>
          <Container className={`${css.contentContainer}`}>
            <QuotesForm agentMetadata={data?.site?.siteMetadata?.agent} />

            <Container className={`${css.disclaimer}`}>
              <span>Disclaimer</span>
              These quotes are only estimates. Your actual premium, deductible,
              and coverage may be different. The quotes offered on this platform
              are not a promise of insurance, and your eligibility can only be
              determined by a qualified agent. Only your qualified agent can
              determine your insurability, plan rates, and health coverage. All
              quotes are subject to change.
            </Container>

            <Container className={ `hidden-on-mobile ${ css.eManualContainer}`}>
              <FileUnknownOutlined /> <a target="_blank" href="https://docs.google.com/presentation/d/16pLZNe3V6zv5WoQqqlhS2mUNqOUZjHLHYjjmVeSmtio/present?slide=id.p">Access the Apollo Quotes E-manual </a>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default IndexPage;
